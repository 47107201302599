import React from 'react';
import '../Chat.css';

const Disclaimer = () => {
    return (
        <div className="Disclaimer">
            Chat bot can make mistakes. Do not share sensitive data. This is not official Solibri product.
        </div>
    );
};

export default Disclaimer;
