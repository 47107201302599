import React, { useState, useContext } from 'react';
import { ChatContext } from '../context/ChatContext';
import '../Chat.css';

const InputBar = () => {
    const [input, setInput] = useState('');
    const { sendMessage, isTyping } = useContext(ChatContext);

    const handleSend = async () => {
        if (input.trim() === '' || isTyping) return; // Ignore send if bot is typing

        const message = input;
        setInput(''); // Clear input field immediately
        await sendMessage(message);
    };

    return (
        <div className="InputContainer">
            <div className="InputBar">
                <input
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' ? handleSend() : null}
                    placeholder="Type a message..."
                    disabled={isTyping} // Disable input if bot is typing
                />
                <button onClick={handleSend} disabled={isTyping}>Send</button>
            </div>
        </div>
    );
};

export default InputBar;
