import React from 'react';
import Chat from './components/Chat';
import InputBar from './components/InputBar';
import Disclaimer from './components/Disclaimer';
import { ChatProvider } from './context/ChatContext';
import './App.css';

const App = () => {
    return (
        <ChatProvider>
            <div className="App">
                <Chat />
                <Disclaimer /> {}
                <InputBar />
            </div>
        </ChatProvider>
    );
};

export default App;
