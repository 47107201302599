import React, { createContext, useState } from 'react';
import axios from 'axios';

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
    const initialMessages = [
        { sender: 'bot', text: 'How can I help you with Solibri?' }
    ];
    const [messages, setMessages] = useState(initialMessages);
    const [buttons, setButtons] = useState([]);
    const [isTyping, setIsTyping] = useState(false);

    const apiUrl = process.env.REACT_APP_BACKEND_API_URL || 'https://soli.chat';

    const addMessage = (sender, text) => {
        setMessages(prevMessages => [...prevMessages, { sender, text }]);
    };

    const sendMessage = async (message, sender = 'user') => {
        if (isTyping) return; // Prevent sending new messages while bot is typing

        if (sender === 'user') {
            addMessage('user', message);
            setButtons([]); // Clear buttons on user message
        }

        setIsTyping(true);

        try {
            const response = await axios.post(`${apiUrl}/api/chat`, { message });
            const newButtons = response.data.followUpButtons || [];
            setButtons(newButtons);
            addMessage('bot', response.data.reply);
        } catch (error) {
            console.error('Error sending message:', error);
            addMessage('bot', 'Error processing your request. Please try again.');
        } finally {
            setIsTyping(false);
        }
    };

    const handleButtonClick = async (text) => {
        await sendMessage(text);
    };

    return (
        <ChatContext.Provider value={{ messages, addMessage, handleButtonClick, buttons, setButtons, sendMessage, isTyping }}>
            {children}
        </ChatContext.Provider>
    );
};

export { ChatContext, ChatProvider };
