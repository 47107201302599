import React, { useContext, useRef, useEffect } from 'react';
import { ChatContext } from '../context/ChatContext';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import '../Chat.css';

const Chat = () => {
    const { messages, buttons, handleButtonClick, isTyping } = useContext(ChatContext);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages, isTyping]);

    const getImageForSender = (sender) => {
        if (sender === 'user') {
            return '/images/imageA.png';
        } else {
            return '/images/imageB.png';
        }
    };

    return (
        <div className="ChatContainer">
            <div className="Messages">
                {messages.map((msg, index) => (
                    <div key={index} className={`Message ${msg.sender}`}>
                        <img src={getImageForSender(msg.sender)} alt={msg.sender} />
                        <div className="MessageContent">
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>{msg.text}</ReactMarkdown>
                        </div>
                    </div>
                ))}
                {isTyping && (
                    <div className="Message bot typing-indicator">
                        <img src={getImageForSender('bot')} alt="bot" />
                        <div className="MessageContent">
                            <div className="typing">
                                <span></span><span></span><span></span>
                            </div>
                        </div>
                    </div>
                )}
                <div ref={messagesEndRef} />
            </div>
            <div className="Buttons">
                {buttons.map((buttonText, index) => (
                    <button key={index} className="FollowUpButton" onClick={() => handleButtonClick(buttonText)}>
                        {buttonText}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Chat;
